<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <translate
        type="text"
        v-model="content.ProductName"
        :languages="languages"
        :label="$t('dtouch.productService.name')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <translate
        type="textArea"
        v-model="content.ProductDescription"
        :languages="languages"
        :label="$t('dtouch.productService.description')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs6>
      <v-text-field
        v-model="content.ProductPrice"
        :label="$t('dtouch.productService.price')"
        outlined
        @input="handleChangeIsModified"
        hide-details
      />
    </v-flex>
    <v-flex xs6 justify-end style="padding-top: 5px;padding-left: 20px;">
      <v-checkbox
        v-model="content.ProductPromotion"
        :label="$t('dtouch.productService.promotion')"
        @change="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <translate
        type="text"
        v-model="content.ProductNote"
        :languages="languages"
        :label="$t('dtouch.productService.note')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('dtouch.productService.image', locale) }}</v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 25px 0 10px 0;">
            <select-image 
              :image="content.ProductImage"
              :onChange="handleChangeImage"
              :locale="locale"
              :imageWidth="600"
              :imageHeight="600"
              :aspectRatio="1/1"
              :folder="workspaceID"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  name: 'Form30ProductService',
  components: {
    Translate,
    SelectImage,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    content: null,
    auxIndication: [],
    allergens: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.config.Languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    }
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
    auxIndication (v) {
      this.editedItem.FoodVegan = v.indexOf(0) >= 0
      this.editedItem.FoodVegetarian = v.indexOf(1) >= 0
      this.editedItem.FoodNew = v.indexOf(2) >= 0
      this.editedItem.FoodRecommended = v.indexOf(3) >= 0

      this.handleChangeIsModified()
    },
  },
  mounted () {
    this.getAllergens()
    this.prepareContent()
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    getAllergens () {
      api.getItem ('dtouch', `v1/public/workspaces/`, this.workspaceID.concat('/allergens'))
        .then(response => {
          this.allergens = response.data
        })
    },
    checkJSON (v) {
      return typeof v == 'object' ? v : JSON.parse(v)
    },
    prepareContent () {
      const aux = this.editedItem
      aux.ProductName = this.checkJSON(aux.ProductName)
      aux.ProductDescription = this.checkJSON(aux.ProductDescription)
      aux.ProductNote = this.checkJSON(aux.ProductNote)
      this.content = aux
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
    handleChangeImage (v) {
      if (v) {
        this.content.ProductImage = v.base64
        this.content.newImage = v 
        this.handleChangeIsModified()
      }
    }
  },
}
</script>

